<template>
  <div v-if="hasCashback" class="mt-3">
    <div
      class="cashback-item-container"
      :class="{'pb-4 mt-4': isLimitedCashback}"
    > 
      <span class="badge-cashback">✅ {{ $t("checkout.guarantee_of_success") }}</span>
      <span
        class="cashback-item"
      >
        <img
          :src="money_cashback"
          alt="icon money cashback"
          style="margin-right: 20px;"
        />
        <div class="cashback-text-container">
          <p v-if="! isLimitedCashback" class="text-cashback-title">
            {{ $t("checkout.with_cashback") }}
          </p>
          <p v-else class="text-cashback-title pt-4">
            {{ $t("checkout.with_limited_cashback") + formatMoney(maximumCashbackAmount) }}
          </p>
          <p class="text-cashback-subtitle">
            {{ $t("checkout.sponsored_by_greenn") }}
          </p>
        </div>
      </span>
    </div>

    <div class="cashback-target-container" >
      <span class="cashback-target-item" >
        <img
          :src="target_cashback"
          alt="icon money cashback target"
          class="mr-4"
        />
        <p class="text-target">
          {{ $t("checkout.sell") }} 
          {{ formatMoney(cashback.meta) }} 
          {{ $t("checkout.in") }} 
          {{ cashback.months }} 
          {{ cashback.months === 1 ? $t("checkout.month") : $t("checkout.months") }} 
          {{ $t("checkout.to_get_cashbash") }} 
        </p>
      </span>
    </div>

  </div>
</template>

<script>
export default {
  name: "Cashback",
  props: {
    cashback: {
      type: Object,
      default: () => {},
    },
    hasCashback: {
      type: Boolean,
      default: false
    },
    isLimitedCashback: {
      type: Boolean,
      default: false
    },
    maximumCashbackAmount: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      money_cashback: require("@/assets/img/icon/money_cashback.svg"),
      target_cashback: require("@/assets/img/icon/target_cashback.svg"),
    };
  },
  methods: {
    formatMoney(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.cashback-item-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 148, 136, 0.1);
  color: #009488;
  height: 65px;
  justify-content: center;
  border-radius: 5px;
  position: relative;
}
.cashback-text-container {
  display: flex;
  flex-direction: column;
  height: 65px;
  justify-content: center;
}
.cashback-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-radius: 5px;
  align-items: center;
  padding: 10px;
  height: 65px;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}
.badge-cashback {
  position: absolute;
  top: -20px;
  right: -10px;
  background: #006B63;
  border-radius: 5px;
  color: #fff;
  padding: 11px;
  padding-top: 6px;
  padding-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}
.cashback-target-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  background-color: #F7F7F7;
}
.cashback-target-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-radius: 5px; 
  align-items: center;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  height: 65px;
  width: 100%;
}
.text-target {
  font-size: 14px !important;
  font-weight: 400;
  text-align: left;
  margin: 0px;
  padding: 0px;
  color: #000000;
}
.text-cashback-title {
  font-size: 14px !important;
  font-weight: bold;
  color: #009488;
  margin: 0px;
  padding: 0px;
}
.text-cashback-subtitle {
  font-size: 11px !important;
  font-weight: 400;
  text-align: left;
  color: #009488;
  margin: 0px;
  padding: 0px;
}
</style>
