function validateCardBrand(cards, worldpayAllowedCards) {
  const cardBins = {
    ELO: [
      [401178, 401179],
      [431274, 431274],
      [438935, 438935],
      [457631, 457632],
      [457393, 457393],
      [504175, 504175],
      [509000, 509002],
      [509064, 509999],
      [509900, 509902],
      [509997, 509999],
      [509964, 509969],
      [509100, 509899],
      [636297, 636297],
      [636368, 636368],
      [506700, 506778],
      [650405, 650439],
      [650485, 650538],
      [650541, 650598],
      [650700, 650718],
      [650720, 650727],
      [650901, 650920],
      [651652, 651679],
      [655000, 655019],
      [655021, 655058],
    ],
    VISA: [[400000, 499999]],
    MASTERCARD: [[510000, 559999]],
  };

  if (!worldpayAllowedCards) return false;

  const allowedCardBrands = worldpayAllowedCards
    .split(";")
    .map((brand) => brand.trim().toUpperCase())
    .filter((brand) => brand);

  return cards.every((card) => {
    const cardNumber = (card?.number || "").replace(/\s+/g, "");
    const bin = parseInt(cardNumber.substring(0, 6), 10);

    const cardBrand = Object.keys(cardBins).find((brand) =>
      cardBins[brand].some(([min, max]) => bin >= min && bin <= max)
    );

    return cardBrand && allowedCardBrands.includes(cardBrand);
  });
}

export {
    validateCardBrand
};
